// this will load the google recaptchas. There can be multiple on one page.
function onloadGRecaptchaCallback() {
  $(".recaptchaDiv").each(function (i, obj) {
    //only load if not already loaded (to cover ajax delayed loading)
    if ($(obj).children().length == 0) {
      var widgetId = grecaptcha.render(obj, {
        sitekey: "6Lfv608UAAAAAKccrAbr96o8xMVbZmOzlH2jKBRH",
      });

      //save the generated id to the parent Div
      $(obj).attr("data-grecaptchaid", widgetId);
    }
  });
}

// This method should be defined globally, because a call for this method is added dynamically to a button link
function increaseNumber(theButton) {
  if (window.event) window.event.preventDefault();

  // get input
  var theInput = $(theButton)
    .closest(".increaseDecreaseButtonArea")
    .children('input[type="number"]');

  //get current value
  var currentVal = parseInt($(theInput).val());

  var maxAllowed = parseInt($(theInput).attr("max"));

  //if undefined set as 1
  if (!$.isNumeric(currentVal)) {
    $(theInput).val(1).trigger("input");
  }
  // only increase if below max value
  else if (maxAllowed != null && currentVal == maxAllowed) {
    //do nothing
  } else {
    $(theInput)
      .val(currentVal + 1)
      .trigger("input");
  }
}

// This method should be defined globally, because a call for this method is added dynamically to a button link
function decreaseNumber(theButton) {
  if (window.event) window.event.preventDefault();

  // get input
  var theInput = $(theButton)
    .closest(".increaseDecreaseButtonArea")
    .children('input[type="number"]');

  //get current value
  var currentVal = parseInt($(theInput).val());

  var minAllowed = parseInt($(theInput).attr("min"));

  //if undefined set as 0
  if (!$.isNumeric(currentVal)) {
    $(theInput).val(0).trigger("input");
  }
  // only decrease if above min value
  else if (minAllowed != null && currentVal == minAllowed) {
    //do nothing
  } else {
    $(theInput)
      .val(currentVal - 1)
      .trigger("input");
  }
}

$(document).ready(function () {
  contactFormClosure();
});

const contactFormClosure = (contactWithin = document) => {
  // ******************************** Variables ***************************

  const anyFormInPageSelector =
    ".autoProcessContactForm, .processAllFormsTogether";

  const stagesBeforeSubmitIdentifier = {
    stageDataAttribute: "stage-before-submit",
    forwardButtonSelector: '.stageControls [data-button-type="forward"]',
    backwardButtonSelector: '.stageControls [data-button-type="backward"]',
  };
  const stagesContainerSelector = "#leadCaptureForm";
  const submitAreaSelector = "[data-stage-submit]";
  const currentStageClass = "current";

  const fieldsSelectors = {
    spacer: '[name="SpacerForEmail"]',
    title: '[name="TitleForEmail"]',
    subject: "#emailSubject",
    newsletterSignup: 'input[name="NewsletterSignup"]',
    emailAddress: '[name="Email Address"]',
    recaptcha: ".recaptchaDiv",
  };

  const validationIdentifiers = {
    inputErrorClass: "validationError",
    messageErrorClass: "validationErrorMessage",
    requiredClass: "required",
    requiredDataAttribute: "is-required",
    emailClass: "emailAddress",
    emailDataAttribute: "is-email-address",
    phoneDataAttribute: "is-phone",
    phoneIncCountryDataAttribute: "is-phone-with-country-code",
  };

  const submitButtonSelector = '[type="submit"]';

  const successMessageSelector = ".successMessage";

  const increaseDecreaseButtonAreaClass = "increaseDecreaseButtonArea";

  // *********************************************************************

  contactSetup(contactWithin);

  function contactSetup(startingElement) {
    //this sets up the contact form processing.
    //	can also call the setup function setupContactFormProcessing directly (e.g. on delayed Ajax loading)
    $(startingElement)
      .find(anyFormInPageSelector)
      .addBack(anyFormInPageSelector)
      .each(function (i, contactForm) {
        setupContactForm(contactForm);
      });

    // setup spacers and titles (keys need to be unique, so append integer)
    // needs to be done just once (to stop duplicate keys) so we call this here, not in setupContactForm

    $spacerCount = 0;

    $(startingElement)
      .find(fieldsSelectors.spacer)
      .each(function () {
        $(this).attr("name", $(this).attr("name") + $spacerCount);
        $spacerCount++;
      });

    $titleCount = 0;

    $(startingElement)
      .find(fieldsSelectors.title)
      .each(function () {
        $(this).attr("name", $(this).attr("name") + $titleCount);
        $titleCount++;
      });
  }

  function setupContactForm(contactForm) {
    const $contactForm = $(contactForm);

    // if the contact form has stages, we setup the stages, i.e., forward/backward etc.
    setupStages($contactForm);

    // we do a validation for required fields on key up. This is in addition to the validation when the next/submit button clicked
    setupRequiredFieldsOnSpotValidation($contactForm);

    //setup plus minus buttons
    setupPlusMinusButtons($contactForm);

    //setup submit button handling
    $contactForm.on("submit", function (e) {
      submitContactForm(e);
    });
  }

  // if the contact form has stages, we setup the stages, i.e., forward/backward etc.
  function setupStages($theForm) {
    // some parts of stages, can be technically placed outside the form. So, we get the parent of the form.
    const $stagesContainer = $theForm.closest(stagesContainerSelector);

    // Do we have no stage (step) for this contact form, i.e., all happen in one step?
    if ($stagesContainer.length === 0) {
      return;
    }

    $stagesContainer
      .find(stagesBeforeSubmitIdentifier.forwardButtonSelector)
      .click((e) => {
        handleNavigationButtons(e, true);
      });
    $stagesContainer
      .find(stagesBeforeSubmitIdentifier.backwardButtonSelector)
      .click((e) => {
        handleNavigationButtons(e, false);
      });

    /**
     * handle navigation for forward/backward buttons
     * @param {event} e The native javascript click event argument.
     * @param {bool} isForwardButton Indicates if the call is for forward button or backward.
     * @returns void
     */
    function handleNavigationButtons(e, isForwardButton) {
      e.preventDefault();

      const $currentStage = $stagesContainer.find(
        `[data-${stagesBeforeSubmitIdentifier.stageDataAttribute}].${currentStageClass}`
      );

      const theLanguage = getLanguage($theForm);

      // validate the current stage if needed, this excludes the last stage which the submit stage because we don't have forward button for it
      // of course we don't have validation for backward button
      if (isForwardButton && !validateAnArea($currentStage, theLanguage)) {
        return;
      }

      // handle transition from current stage to the next stage (or previous stage if it is backward button) UI wise.
      handleStagesUiTransition(isForwardButton);
    }

    /**
     * Handle transition from current stage to the next stage (or previous stage if it is backward button) UI wise.
     * @param {bool} isForwardButton Indicates if the call is for forward button or backward.
     */
    function handleStagesUiTransition(isForwardButton) {
      // hide stages container temporarily, until we load the next stage
      $stagesContainer.animate({ opacity: "0" });

      setTimeout(() => {
        // find all elements with the current class, this would be the stage, the buttons and stage progress
        let $element = $stagesContainer
          .find(`.${currentStageClass}`)
          .removeClass(currentStageClass);

        // what would be the next step, forward or backward?
        if (isForwardButton) {
          $element = $element.next();
        } else {
          $element = $element.prev();
        }

        // add the class to the next step
        $element.addClass(currentStageClass);
      }, 600);

      // show stages container again
      $stagesContainer.animate({ opacity: "1.0" });
    }
  }

  /**
   * It adds a validation for required fields on key up. This is in addition to the validation when the next/submit button clicked.
   * @param {JQueryObject} $theForm The form which is converted to a JQuery object.
   */
  function setupRequiredFieldsOnSpotValidation($theForm) {
    // The validation is done for elements that are typeable
    $theForm
      .find("input,textarea")
      .filter(
        `.${validationIdentifiers.requiredClass}, [data-${validationIdentifiers.requiredDataAttribute}]`
      )
      // when the text box is clicked, and user releases a key.
      .keyup((event) => {
        // Converting the current target, i.e., the text box, to a JQuery object.
        const $element = $(event.currentTarget);

        //Remove old validation error classes on this text box, because we want to revalidate the text box again in respect of "required validation"
        $element.removeClass(validationIdentifiers.inputErrorClass);

        //remove old validation messages
        $element
          .next(
            `.${validationIdentifiers.messageErrorClass}[data-error-for="requiredText"]`
          )
          .remove();

        // Now, if the box is empty after releasing the key (in most cases this means the key that was just released was Backspace)
        if ($element.val()?.trim() == "") {
          addErrorMessage($element, "requiredText", getLanguage($theForm));
        }
      });
  }

  /**
   * A utility function to find the current language of the contact page
   * @param {JQueryObject} $theForm he form which is converted to a JQuery object.
   * @returns A string that indicates the language
   */
  function getLanguage($theForm) {
    // set the language. if not set use english as the default
    let theLanguage;

    if ($theForm.attr("data-language")) {
      theLanguage = $theForm.data("language");
    } else {
      theLanguage = "english";
    }
    return theLanguage;
  }

  /**
   * It processes the form to submit to the PHP file, i.e., anyContactFormProcessor.php
   * @param {event} e The native javascript of form submit argument.
   */
  function submitContactForm(e) {
    // stop default submit form action from happening
    e.preventDefault();

    var $theForm = $(e.target);

    // set the language. if not set use english as the default
    const theLanguage = getLanguage($theForm);

    // if we have stages, we have a submit area which is basically the last stage
    // otherwise for those contact forms that we have only one stage, the whole form is considered as the submit area
    let $submitArea = $theForm.find(submitAreaSelector);
    if ($submitArea.length === 0) {
      $submitArea = $theForm;
    }

    // we first validate the submit area locally, and then post data to the server, i.e., anyContactFormProcessor.php
    if (validateAnArea($submitArea, theLanguage) !== false) {
      // email subject
      // can use values from multiple fields to create the email subject and they will be concatenated
      $theForm.find(".useForEmailSubject").each(function () {
        $theForm
          .find(fieldsSelectors.subject)
          .val($theForm.find(fieldsSelectors.subject).val() + $(this).val());
      });

      // let user know message is sending
      var $sendButton = $theForm.find(submitButtonSelector);
      var oldButtonMessage = $sendButton.val();

      // while submiting the data, a 'disabled' attribute added to the button. So, we need to have an appropriate styling for this case.
      $sendButton
        .val(theLanguage === "french" ? "Envoi..." : "sending...")
        .prop("disabled", true);

      // submit to mailchimp, if appropriate
      if ($theForm.attr("data-mailchimpURL")) {
        var $NewsletterSignup = $theForm.find(fieldsSelectors.newsletterSignup);

        if ($NewsletterSignup.prop("checked")) {
          var mcURL = $theForm.attr("data-mailchimpURL");

          //find their email address
          var guestEmail = $theForm
            .find('input[data-mailchimpEmail="true"]')
            .val();
          var guestName = $theForm
            .find('input[data-mailchimpName="true"]')
            .val();
          var guestFirstName = guestName.slice(0, guestName.indexOf(" "));

          //create new form
          var $mailchimpForm = $(
            '<form action="' +
              mcURL +
              '" id="mailchimpSignUpForm" ><input name="EMAIL" value="' +
              guestEmail +
              '"/><input name="FNAME" value="' +
              guestFirstName +
              '"/></form>'
          );

          //append to page
          $("body").append($mailchimpForm);

          //submit new form
          //var $mcForm = $('#mailchimpSignUpForm');

          $.ajax({
            type: "GET",
            url: $($mailchimpForm).attr("action"),
            data: $($mailchimpForm).serialize(),
            cache: false,
            dataType: "jsonp",
            jsonp: "c", // trigger MailChimp to return a JSONP response
            contentType: "application/json; charset=utf-8",
            error: function (error) {
              // According to jquery docs, this is never called for cross-domain JSONP requests

              addNewsletterSignupResultToForm(
                $theForm,
                "Will add to newsletter list."
              );

              // send email
              postEmailToServer(
                $theForm,
                $sendButton,
                oldButtonMessage,
                theLanguage
              );
            },
            success: function (data) {
              var NewsLetterSignupResult;

              if (data.result != "success") {
                NewsLetterSignupResult =
                  data.msg || "Will add to newsletter list.";
              } else {
                NewsLetterSignupResult = "success!";
              }

              addNewsletterSignupResultToForm($theForm, NewsLetterSignupResult);

              // send email
              postEmailToServer(
                $theForm,
                $sendButton,
                oldButtonMessage,
                theLanguage
              );
            },
          });
        } else {
          // do not submit to mailchimp, so go straight to email sending
          postEmailToServer(
            $theForm,
            $sendButton,
            oldButtonMessage,
            theLanguage
          );
        }
      } else {
        // do not submit to mailchimp, so go straight to email sending
        postEmailToServer($theForm, $sendButton, oldButtonMessage, theLanguage);
      }
    }
  }

  function addNewsletterSignupResultToForm(theForm, SUResult) {
    //remove embedded links from error messages
    SUResult = SUResult.slice(0, SUResult.indexOf("<"));

    $(theForm).append(
      '<input type="hidden" name="Newsletter Signup Result" value="' +
        SUResult +
        '"/>'
    );
  }

  /**
   * It filters out empty inputs for when the class hideEmptyFields is used.
   * @param {HtmlElement} theInput Native Html element for the input
   * @returns false if the input is empty, otherwise true..
   */
  function filterEmptyInputs(theInput) {
    if (
      $(theInput).attr("type") == "number" ||
      $(theInput).attr("type") == "text" ||
      $(theInput).attr("type") == "hidden"
    ) {
      // var toReturn = $(theInput).val() !== '';

      // console.log( $(theInput).attr('name') + ': ' + toReturn + '\n');

      //return toReturn;

      return $(theInput).val() !== "";
    } else {
      return true;
    }
  }

  /**
   * Posts the data to the server, i.e., anyContactFormProcessor.php
   * @param {JQueryObject} $theForm he form which is converted to a JQuery object.
   * @param {JQueryObject} $sendButton The submit button, being used to re-enable the button.
   * @param {string} oldButtonMessage The original text for the submit button, being used when the submit button is being re-enabled.
   * @param {string} theLanguage The current language of the page.
   */
  function postEmailToServer(
    $theForm,
    $sendButton,
    oldButtonMessage,
    theLanguage
  ) {
    //get the data to send to server
    var $serializedData;

    if ($theForm.hasClass("processAllFormsTogether hideEmptyFields")) {
      $serializedData = $(".processAllFormsTogether :input")
        .filter(function () {
          return filterEmptyInputs(this);
        })
        .serialize();

      //$data = $('.processAllFormsTogether :input').serialize();
    } else if ($theForm.hasClass("processAllFormsTogether")) {
      $serializedData = $(".processAllFormsTogether :input").serialize();
    } else if ($theForm.hasClass("hideEmptyFields")) {
      $serializedData = $theForm
        .find(":input")
        .filter(function () {
          return filterEmptyInputs(this);
        })
        .serialize();

      //$data = $(theForm).find(':input').serialize();
    } else {
      $serializedData = $theForm.serialize();
    }

    // post submission to server, i.e., anyContactFormProcessor.php
    $.ajax({
      type: "post",
      url: $theForm.data("form-processor-location"),
      data: $serializedData,
      beforeSend: () => {
        // we trigger an event on the form, beforeSubmit, so if another js file wants to do something before submitting the data
        $theForm.trigger("beforeSubmit");
        return true;
      },
      success: function (response) {
        // we trigger an event on the form, afterSuccess, so if another js file wants to do something after successful  submission
        $theForm.trigger("afterSuccess", response);

        // re-enable the send button
        resetSendButton();

        //hide the form and show success message (must be sibling to the form and have class successMessage)
        $theForm.fadeOut(500, function () {
          $theForm
            .parent()
            .find(successMessageSelector)
            .fadeIn(100, function () {
              $("html, body").animate(
                {
                  scrollTop:
                    $theForm
                      .parent()
                      .find(successMessageSelector)
                      .first()
                      .offset().top - 100,
                },
                500
              );
            });
        });

        //record success in Google Analytics
        if (ga)
          ga("send", {
            hitType: "event",
            eventCategory: "FormSubmission",
            eventAction: "ButtonClick",
            eventLabel: $theForm.attr("id"),
          });
      },
      error: function (response) {
        // we trigger an event on the form, afterError, so if another js file wants to do something after unsuccessful submission
        $theForm.trigger("afterError", response);

        // we don't have statusText in http/2 (server), so using a custom header, reason-phrase, we created.
        if (
          response.statusText == "Recaptcha Incorrect" ||
          response.getResponseHeader("reason-phrase") == "Recaptcha Incorrect"
        ) {
          addErrorMessage(
            $theForm.find(fieldsSelectors.recaptcha),
            "recaptchaNotTicked",
            theLanguage
          );

          grecaptcha.reset();

          scrollToElementInError();
        } else if (
          response.statusText == "Email Invalid" ||
          response.getResponseHeader("reason-phrase") == "Email Invalid"
        ) {
          addErrorMessage(
            $theForm.find(fieldsSelectors.emailAddress),
            "emailNotValid",
            theLanguage
          );

          grecaptcha.reset();

          scrollToElementInError();
        } else {
          addErrorMessage(
            $theForm.find(submitButtonSelector),
            "unknownError",
            theLanguage
          );
        }

        resetSendButton();
      },
      complete: function (response) {
        // we trigger an event on the form, afterComplete, so if another js file wants to do something after the submission regardless of success or failure of submission
        $theForm.trigger("afterComplete", response);
      },
    });

    /**
     * It re-enables the send button
     */
    function resetSendButton() {
      $sendButton.val(oldButtonMessage).prop("disabled", false);
    }
  }

  /**
   *
   * @param {JQueryObject} $area The area for which we are removing the validation errors. It could be the submit area or a particular stage.
   */
  function removeOldValidationWarningsFromArea($area) {
    //Remove old validation error classes
    $area
      .find(`.${validationIdentifiers.inputErrorClass}`)
      .removeClass(validationIdentifiers.inputErrorClass);

    //remove old validation messages
    $area.find(`.${validationIdentifiers.messageErrorClass}`).remove();

    firstElementInError = null;
  }

  /**
   * Being used to set the focus on the first element with validation error.
   */
  let firstElementInError = null;

  /**
   * It adds a proper validation error style and message for the element.
   * @param {JQueryObject} $element The element for which we want to add the validation error.
   * @param {string} errCode The validation error type, e.g., required filed error. The codes defined down in this file.
   * @param {string} language The current language of the page.
   */
  function addErrorMessage($element, errCode, language) {
    $element.addClass(validationIdentifiers.inputErrorClass);

    //css hides validationErrorMessages by default, so need to display block here.
    $element.after(
      `<div data-error-for="${errCode}" class="${validationIdentifiers.messageErrorClass}" style="display:block;">` +
        getErrorMessage(errCode, language) +
        "</div>"
    );

    if (firstElementInError == null) {
      firstElementInError = $element;
    }
  }

  /**
   * Scroll to first element in error
   */
  function scrollToElementInError() {
    firstElementInError.focus();
    firstElementInError = null;
  }

  //*************** error messages *****************

  //object type to store useful error messages in

  function errorCodeDescriptor(errorCode, english, french) {
    this.errorCode = errorCode;
    this.english = english;
    this.french = french;
  }

  const errorCodes = [];

  errorCodes.push(
    new errorCodeDescriptor(
      "http",
      "http is not allowed in entries",
      "http n'est pas autoris&eacute;"
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "requiredText",
      "Please enter a value.",
      "Entrez une valeur, il est n&eacute;cessaire"
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "requiredCheck",
      "This is required to continue. Thank you.",
      "Ceci est n&eacute;cessaire pour continuer. Je vous remercie."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "requiredSelection",
      "Please select an option.",
      "Veuillez s&eacute;lectionner une option. Je vous remercie"
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "emailNotValid",
      "Please check your email address.",
      "V&eacute;rifiez votre adresse e-mail, cela ne semble pas &ecirc;tre valide. Je vous remercie."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "phoneNotValid",
      "Please check your phone number.",
      "Veuillez v&eacute;rifiez votre num&eacutero de t&eacutel&eacutephone, Celui-ci ne semble pas &ecirc;tre valide. Je vous remercie."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "phoneIncCountryNotValid",
      "Please check your phone number. Please include the country code starting with + (e.g +353 / +44), but do not include any brackets.",
      "Veuillez v&eacute;rifiez votre num&eacutero de t&eacutel&eacutephone. Veuillez inclure le code du pays commençant par +33, +44 etc."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "valuesDoNotMatch",
      "Please check. These values do not match.",
      "V&eacute;rifiez s&apos;il vous pla&icirc;t. Ces valeurs ne correspondent pas. Je vous remercie."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "recaptchaNotTicked",
      "Please tick the 'I'm not a Robot' box. Thank you.",
      "Veuillez compl&eacute;ter l'anti-spam en cochant la case. Je vous remercie."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "singleOccupancyNotSelected2PerRoom",
      "You must select the number of Single Occupancy Rooms you require (max 2 people per room).",
      "You must select the number of Single Occupancy Rooms you require (max 2 people per room)."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "unknownError",
      "I'm sorry, there was an error and the message cannot be sent. Please email or phone us instead. We're sorry for the inconvenience.",
      "Je suis d&eacute;sol&eacute;, il y a eu une erreur et le message ne peut pas &ecirc;tre envoy&eacute;. Pourriez-vous nous envoyer un courriel ou nous t&eacute;l&eacute;phoner plut&ocirc;t s'il vous plait? Nous nous excusons pour le d&eacute;sagr&eacute;ment."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "voucherCodeInvalid",
      "I'm sorry, that does not appear to be a valid voucher code.",
      "Je suis d&eacute;sol&eacute;, cela ne semble pas &ecirc;tre un code de bon valable."
    )
  );

  errorCodes.push(
    new errorCodeDescriptor(
      "voucherCodeExpired",
      "I'm sorry, that voucher code has expired.",
      "Je suis d&eacute;sol&eacute;, ce code de bon a expir&eacute;."
    )
  );

  function getErrorMessage(errCode, language) {
    for (var i = 0, len = errorCodes.length; i < len; i++) {
      if (errorCodes[i]["errorCode"] == errCode) {
        return errorCodes[i][language];
      }
    }
  }

  /**
   * It validates the area, could be the submit area or one particular stage.
   * @param {JQueryObject} $area The area for which we are doing the validation, i.e., submit area or a particular stage.
   * @param {string} theLanguage The current language of the page.
   * @returns true if the area is valid, otherwise false.
   */
  function validateAnArea($area, theLanguage) {
    removeOldValidationWarningsFromArea($area);

    let validationSuccess = true;

    // check all fields
    $area
      .find(`input:not(${submitButtonSelector}), textarea, select`)
      .each(function () {
        const $element = $(this);

        // Check for code insertion
        if ($element.val().toLowerCase().indexOf("http") != -1) {
          addErrorMessage($element, "http", theLanguage);
          validationSuccess = false;
        }

        //check if is required
        if (
          $element.hasClass(validationIdentifiers.requiredClass) ||
          $element.data(validationIdentifiers.requiredDataAttribute) !==
            undefined
        ) {
          if ($element.is("input,textarea") && $element.val()?.trim() == "") {
            addErrorMessage($element, "requiredText", theLanguage);
            validationSuccess = false;
          }

          if (
            $element.is('input[type="checkbox"]') &&
            !$element.prop("checked")
          ) {
            addErrorMessage($element, "requiredCheck", theLanguage);
            validationSuccess = false;
          }

          if ($element.is("select") && $element.val() == "notSet") {
            addErrorMessage($element, "requiredSelection", theLanguage);
            validationSuccess = false;
          }
        }

        //check if is an email address
        if (
          ($element.hasClass(validationIdentifiers.emailClass) ||
            $element.data(validationIdentifiers.emailDataAttribute) !==
              undefined) &&
          $element.val() != ""
        ) {
          if ($element.val().indexOf("@") == -1) {
            addErrorMessage($element, "emailNotValid", theLanguage);
            validationSuccess = false;
          }
        }

        ///^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g
        //check if is a phone number, we are using a regular expression for this
        if (
          $element.data(validationIdentifiers.phoneDataAttribute) !==
            undefined &&
          $element.val() != "" &&
          !$element
            .val()
            .match(
              /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            )?.length
        ) {
          addErrorMessage($element, "phoneNotValid", theLanguage);
          validationSuccess = false;
        }

        //check if is a phone number, we are using a regular expression for this
        if (
          $element.data(validationIdentifiers.phoneIncCountryDataAttribute) !==
            undefined &&
          $element.val() != "" &&
          !$element.val().match(/^[\+]{1}[0-9 ]{12,18}$/im)?.length
        ) {
          addErrorMessage($element, "phoneIncCountryNotValid", theLanguage);
          validationSuccess = false;
        }

        if ($element.data("matching-field")) {
          var baseValue = $element.val();

          var matchingFieldValue = $element.data("matching-field");

          // get all the matching fields
          $area
            .find("[data-matching-field='" + matchingFieldValue + "']")
            .each(function (index, toMatchElement) {
              if ($(toMatchElement).val() != baseValue) {
                addErrorMessage(
                  $(toMatchElement),
                  "valuesDoNotMatch",
                  theLanguage
                );
                validationSuccess = false;
              }
            });
        }
      });

    //Check if google recaptcha has been passed
    var gRecaptchaId = $area
      .find(fieldsSelectors.recaptcha)
      .data("grecaptchaid");
    if (gRecaptchaId != undefined) {
      var response = grecaptcha.getResponse(gRecaptchaId);

      if (response.length == 0) {
        $area
          .find(fieldsSelectors.recaptcha)
          .addClass(validationIdentifiers.inputErrorClass);

        addErrorMessage(
          $area.find(fieldsSelectors.recaptcha),
          "recaptchaNotTicked",
          theLanguage
        );

        validationSuccess = false;
      }
    }

    //scroll to first element in error
    if (!validationSuccess) {
      scrollToElementInError();
    }

    //All conditions passed so return true.
    return validationSuccess;
  }

  function setupPlusMinusButtons($contactForm) {
    $contactForm.find('input[type="number"]').each(function () {
      //see if it's already been setup. This way we can call this function again later on when extra number inputs have been created.
      if (!$(this).next().hasClass(increaseDecreaseButtonAreaClass)) {
        $(this).wrap(`<div class="${increaseDecreaseButtonAreaClass}"></div>`);

        $(this).before(
          '<div onclick="decreaseNumber($(this))" class="decreaseButton"><i class="fa fa-fw fa-minus"></i></div>'
        );

        $(this).after(
          '<div onclick="increaseNumber($(this))" class="increaseButton"><i class="fa fa-fw fa-plus"></i></div>'
        );
      }
    });
  }
};
